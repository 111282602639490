<template>
    <div class="skills">
        <div class="skills__heading">Zručnosti</div>
    </div>
</template>

<script>
export default {
  name: 'Skills',
  props: {}
}
</script>

<style lang="scss">
    .skills {
        background-color: darken($c-white, 2);
        padding: 5rem;

        &__heading {
            display: inline-block;
            font-weight: 700;
            // color: $c-primary;
            font-size: $font-26px;
            margin-bottom: 2rem;
            padding-bottom: .5rem;
            border-bottom: 1px solid rgba($c-black, .75);
        }
    }
</style>