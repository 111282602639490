<template>
    <div class="contact">
        <div class="contact__heading">Kontakt</div>
    </div>
</template>

<script>
export default {
  name: 'Kontakt',
  props: {}
}
</script>

<style lang="scss">
    .contact {
        color: $c-white;
        background-color: $c-tertiary;
        padding: 5rem;

        &__heading {
            display: inline-block;
            font-weight: 700;
            // color: $c-primary;
            font-size: $font-26px;
            margin-bottom: 2rem;
            padding-bottom: .5rem;
            border-bottom: 1px solid $c-white;
        }
    }
</style>