<template>
    <div class="cv">
        <div class="cv__container">
            <div class="cv__category">
                <div class="cv__heading">Pracovné skúsenosti</div>
                <div class="cv__content">
                    <div class="cv__item">
                        <div class="cv__head">
                            <div class="cv__title">Web developer</div>
                            <div class="cv__date">11/2017 – 01/2021</div>
                        </div>
                        <div class="cv__institution">inQool a.s.</div>
                        <div class="cv__description">Tvorba webových stránok pre Magistrát mesta Brna a iné inštitúcie, či firmy, predovšetkým na platforme WordPress.</div>
                    </div>
                    <div class="cv__item">
                        <div class="cv__head">
                            <div class="cv__title">SZČO</div>
                            <div class="cv__date">10/2016 - súčasnosť</div>
                        </div>
                        <div class="cv__institution">Stanislav Cingel - Cingel Design</div>
                        <div class="cv__description">Návrh a implementácia webových stránok na platforme WordPress a WooCommerce.</div>
                    </div>
                </div>
            </div>
            <div class="cv__category">
                <div class="cv__heading">Vzdelanie</div>
                <div class="cv__content">
                    <div class="cv__item">
                        <div class="cv__head">
                            <div class="cv__title">Aplikovaná informatika (Bc.)</div>
                            <div class="cv__date">09/2017 – 07/2021</div>
                        </div>
                        <div class="cv__institution">Fakulta Informatiky Masarykovej univerzity</div>
                        <div class="cv__description">Štúdium úspešne zakončené s titulom Bc.</div>
                    </div>
                    <div class="cv__item">
                        <div class="cv__head">
                            <div class="cv__title">Aplikovaná informatika (Mgr.)</div>
                            <div class="cv__date">09/2021 – súčasnosť</div>
                        </div>
                        <div class="cv__institution">Prírodovedecká fakulta Univerzity Palackého v Olomouci</div>
                        <div class="cv__description">Nadväzujúce magisterské štúdium so špecializáciou Vývoj software.<br>Očakávané ukončenie – leto 2023.</div>
                    </div>
                </div>
            </div>
            <div class="cv__category cv__category_compact">
                <div class="cv__heading">Jazyky</div>
                <div class="cv__content">
                    <div class="cv__langs">
                        <div class="cv__lang">
                            <div class="cv__flag cv__flag_gb"></div> C1
                        </div>
                        <div class="cv__lang">
                            <div class="cv__flag cv__flag_ru"></div> B1
                        </div>
                        <div class="cv__lang">
                            <div class="cv__flag cv__flag_de"></div> A2
                        </div>
                    </div>
                </div>
            </div>
            <div class="cv__category cv__category_compact">
                <div class="cv__heading">Záľuby</div>
                <div class="cv__content">cyklistika, gitara, akordeón, chlastanie, programovanie, geografia, história</div>
            </div>
            <div class="cv__category cv__category_compact">
                <div class="cv__download">
                    <div class="cv__button">
                        <div class="cv__button_icon">↓</div>
                        Stiahnuť životopis<br>vo formáte .pdf
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
export default {
  name: 'Main',
  props: {
    msg: String
  }
}
</script>

<style lang="scss">
.cv {
    padding: 5rem 5rem 0;
    background-color: $c-primary;
    // background-color: $c-yellow;
    color: $c-white;

    &__container {
        display: flex;
        flex-wrap: wrap;
    }

    &__category {
        flex-shrink: 0;
        flex-basis: 50%;
        margin-bottom: 5rem;

        &:not(:last-child) {
            padding-right: 5rem;
        }

        &_compact {
            flex-basis: 33.33%;
        }
    }

    &__heading {
        display: inline-block;
        font-weight: 700;
        // color: $c-primary;
        font-size: $font-26px;
        margin-bottom: 2rem;
        padding-bottom: .5rem;
        border-bottom: 1px solid rgba($c-white, .75);
    }

    &__content {
        font-size: 20px;
        line-height: 1.35em;
    }

    &__item {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 3.5rem;

            // &:after {
            //     content: '';
            //     display: block;
            //     width: 50px;
            //     height: 1px;
            //     background-color: rgba($c-white, .75);
            //     position: absolute;
            //     bottom: -40px;
            // }
        }
    }

    &__head {
        display: flex;
        // align-items: baseline;
        // flex-wrap: wrap;
        margin-bottom: .25rem;
        // border-bottom: 1px solid $c-white;
    }

    &__date {
        font-size: $font-20px;
        // color: $c-tertiary;
        opacity: .85;
        font-weight: 300;
        float: right;
        padding-top: .15rem;
        // flex-basis: 100%;
    }

    &__title {
        font-weight: 700;
        // color: $c-tertiary;
        font-size: $font-26px;
        margin-right: 1rem;
        flex-grow: 1;
    }

    &__institution {
        font-weight: 600;
        // color: #ffee00;
        font-size: 18px;
        margin-bottom: 1rem;
    }

    &__description {
        font-size: $font-20px;
        line-height: 1.35em;
    }

    &__langs {
        display: flex;
        flex-wrap: wrap;
    }

    &__lang {
        display: flex;
        align-items: center;
        background-color: $c-secondary;
        border-radius: 100px;
        font-weight: 700;
        padding: .35rem;
        padding-right: 1rem;
        // color: $c-font-black;
        margin-bottom: .75rem;

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__flag {
        height: 2.25rem;
        width: 2.25rem;
        background-size: cover;
        background-position: center;
        margin-right: .75rem;
        border-radius: 100px;

        &_gb {
            background-image: url(../assets/gb.svg);
        }

        &_ru {
            background-image: url(../assets/ru.svg);
        }

        &_de {
            background-image: url(../assets/de.svg);
        }
    }

    &__download {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__button {
        width: fit-content;
        // background-color: $c-white;
        border: 1px solid $c-white;
        padding: .85rem 1.25rem;
        border-radius: .35rem;
        font-weight: 600;
        font-size: 22px;
        cursor: pointer;
        // border-bottom: 4px solid $c-gray;
        // color: $c-font-black;
        display: flex;
        align-items: center;
        line-height: 1.45em;
    }

    &__button_icon {
        font-size: 2.5em;
        font-weight: 300;
        // border-right: 1px solid rgba($c-black, .25);
        // margin-right: 1.5rem;
        padding-right: .75rem;
        // color: $c-primary;
        // text-decoration: underline;
    }
}
</style>