<template>
    <div class="projects">
        <div class="projects__container">
            <div class="projects__item projects__item_najdispoj">
                <div class="projects__details">
                    <div class="projects__logo">
                        <img class="projects__img" src="../assets/najdispoj_logo.png">
                    </div>
                    <div class="projects__techs">
                        <div class="projects__tech">Vue.js</div>
                        <div class="projects__tech">Sass</div>
                        <div class="projects__tech">Python</div>
                        <div class="projects__tech">FastAPI</div>
                        <div class="projects__tech">Leaflet</div>
                        <div class="projects__tech">OpenTripPlanner</div>
                    </div>
                    <div class="projects__description">
                        Cieľom open-source projektu NájdiSpoj.sk je sprehľadniť mestskú hromadnú dopravu v Bratislave tak, aby jej rozumel každý. Súčasná verzia bola vytvorená v rámci mojej bakalárskej práce, dostupnej <a href="">tu</a>.
                    </div>
                    <div class="projects__buttons">
                        <div class="projects__button">Navštíviť »</div>
                        <div class="projects__button projects__button_alt">Zdrojový kód</div>
                    </div>
                </div>
                <div class="projects__screenshot">
                    <img class="projects__img" src="../assets/najdispoj.png">
                </div>
            </div>

            <div class="projects__item projects__item_adbaits">
                <div class="projects__details">
                    <div class="projects__logo">
                        <img class="projects__img" src="../assets/adbaits_logo.png">
                    </div>
                    <div class="projects__techs">
                        <div class="projects__tech">WordPress</div>
                        <div class="projects__tech">WooCommerce</div>
                        <div class="projects__tech">PHP</div>
                        <div class="projects__tech">Sass</div>
                        <div class="projects__tech">Twig</div>
                    </div>
                    <div class="projects__description">
                        E-shop slovenského výrobcu rybárskych potrieb. Vytvorený v CMS WordPress, s pluginom WooCommerce. Pozostáva z vlastnej témy vytvorenej pomocou PHP so šablónovým systémom Twig a vlastných post typov. 
                    </div>
                    <div class="projects__buttons">
                        <div class="projects__button">Navštíviť »</div>
                    </div>
                </div>
                <div class="projects__screenshot">
                    <img class="projects__screenimg" src="../assets/adbaits.png">
                </div>
            </div>
            <div class="projects__inqool">
                <div class="projects__text">Počas mojich 3 rokov strávených vo firme inQool a.s. som sa v rôznej miere podieľal (mimo iného) na vývoji nasledujúcich webov:</div>
                <div class="projects__grid">
                    <a class="projects__compact projects__compact_crr" href="">crr.cz</a>
                    <a class="projects__compact projects__compact_tacr" href="">tacr.cz</a>
                    <a class="projects__compact projects__compact_grantys" href="">grantys.cz</a>
                    <a class="projects__compact projects__compact_trencin" href="">trencin.sk</a>
                    <a class="projects__compact projects__compact_prazskyvoucher" href="">prazskyvoucher.cz</a>
                    <a class="projects__compact projects__compact_mestouvaly" href="">mestouvaly.cz</a>
                    <a class="projects__compact projects__compact_pvpbukov" href="">pvpbukov.cz</a>
                    <a class="projects__compact projects__compact_nsz" href="">verejnazaloba.cz</a>
                    <a class="projects__compact projects__compact_socharske" href="">socharske.brno.cz</a>
                    <a class="projects__compact projects__compact_brno2050" href="">brno2050.cz</a>
                    <a class="projects__compact projects__compact_darujmekrev" href="">darujmekrev.brno.cz</a>
                    <a class="projects__compact projects__compact_plugandplay" href="">plugandplay.brno.cz</a>
                    <a class="projects__compact projects__compact_probudejce" href="">probudejce.cz</a>
                    <a class="projects__compact projects__compact_mujkraj" href="">mujkraj.kr-stredocesky.cz</a>
                    <a class="projects__compact projects__compact_sfzp" href="">sfzp.cz</a>
                    <a class="projects__compact projects__compact_praha7" href="">praha7.cz</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Projects',
  props: {}
}
</script>

<style lang="scss">
    .projects {
        padding: 5rem;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__item {
            display: flex;
            max-width: 1250px;
            padding-bottom: 4rem;
            border-bottom: 1px solid rgba($c-black, .1);
            margin-bottom: 4rem;

            &:nth-child(2n) {
                flex-direction: row-reverse;
            }
        }

        &__details {
            flex-grow: 1;
            padding-right: 2.5rem;
            display: flex;
            flex-direction: column;
        }

        &__logo {
            width: 300px;
            margin-bottom: 1.5rem;

            .projects__item_adbaits & {
                width: 100px;
            }
        }

        &__img {
            width: 100%;
        }

        &__techs {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
        }

        &__tech {
            margin-bottom: .75rem;
            // font-size: $font-20px;
            font-weight: 600;
            background-color: darken($c-white, 5);
            padding: .35rem .65rem;
            border-radius: 100px;

            &:not(:last-child) {
                margin-right: .75rem;
            }
        }

        &__screenshot {
            flex-shrink: 0;
            flex-basis: 500px;
            border-radius: .5rem;
            overflow: hidden;
            box-shadow: 0 2rem 2rem -1.5rem rgba($c-black, .5);

            .projects__item:nth-child(2n) & {
                margin-right: 3rem;
            }
        }

        &__screenimg {
            width: 100%;
        }

        &__info {
            padding: 2rem;
            flex-basis: 50%;
        }

        &__address {
            font-weight: 700;
            font-size: $font-26px;
            margin-bottom: 1.5rem;
            display: inline-block;
        }

        &__description {
            font-size: $font-20px;
            line-height: 1.45em;
            flex-grow: 1;
            margin-bottom: 1.5rem;
        }

        &__buttons {
            display: flex;
        }

        &__button {
            padding: .85rem 1.25rem;
            border-radius: .35rem;
            font-weight: 600;
            font-size: 22px;
            cursor: pointer;
            
            &:not(:last-child) {
                margin-right: 1rem;
            }

            .projects__item_najdispoj &:not(&_alt) {
                background-color: $c-najdispoj;
                color: $c-white;
                border-bottom: 4px solid darken($c-najdispoj, 20);
            }

            .projects__item_adbaits &:not(&_alt) {
                background-color: $c-adbaits;
                color: $c-white;
                border-bottom: 4px solid darken($c-adbaits, 20);
            }

            &_alt {
                background-color: $c-gray !important;
                border-bottom: 4px solid darken($c-gray, 15);
            }
        }

        &__inqool {
            width: 100%;
            max-width: 1250px;
        }

        &__text {
            margin-bottom: 2rem;
            font-size: $font-20px;
            // font-weight: 600;
            text-align: center;
        }

        &__grid {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-gap: 1rem;
            color: $c-white;
        }

        &__compact {
            @include a-unstyled;

            text-decoration: none;
            font-weight: 600;
            font-size: $font-18px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            height: 100px;
            // 
            border-radius: 1rem;

            &_trencin {
                background-color: #009EE3;
            }
            &_tacr {
                background-color: #F03741;
            }
            &_brno2050 {
                border: 1px solid rgba($c-black, .15);
                color: #1D1D1B;
                font-weight: 700;
            }
            &_darujmekrev {
                border: 1px solid rgba($c-black, .15);
                color: #DF2726;
            }
            &_socharske {
                border: 1px solid rgba($c-black, .15);
                color: #1D1D1B;
                font-weight: 300;
            }
            &_plugandplay {
                border: 1px solid rgba($c-black, .15);
                color: #DF2726;
            }
            &_grantys {
                border: 1px solid rgba($c-black, .15);
                color: #0094D2;
            }
            &_prazskyvoucher {
                background-color: #c81428;
            }
            &_nacr {
                border: 1px solid rgba($c-black, .15);
                color: #004B93;
                font-weight: 700;
            }
            &_nsz {
                background-color: #c30043;
            }
            &_probudejce {
                border: 1px solid rgba($c-black, .15);
                color: #1DBCFF;
            }
            &_mujkraj {
                background-color: #E31F27;
            }
            &_praha7 {
                border: 1px solid rgba($c-black, .15);
                color: #1B4E9B;
            }
            &_mestouvaly {
                background-color: #7bbb57;
            }
            &_sfzp {
                background-color: #2DA343;
            }
            &_pvpbukov {
                background-color: #DBE5DD;
                color: $c-font-black;
            }
            &_crr {
                background-color: #014A94;
            }
        }
    }
</style>