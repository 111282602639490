<template>
  <div class="page">
    <div class="main">
      <div class="intro">
        <div class="intro__main">
          <h1 class="intro__heading"><span class="intro__logo">»</span>Dobrý deň, moje meno je <span class="intro__name">Stanislav Cingel</span>.</h1>
          <div class="intro__text">
            <p>Som absolvent bakalárskeho študijného programu Aplikovaná informatika na <strong>Fakulte Informatiky Masarykovej univerzity</strong> v Brne.
            V&nbsp;štúdiu Aplikovanej informatiky na magisterskom stupni pokračujem na <strong>Univerzite Palackého v&nbsp;Olomouci</strong>.
            Popri štúdiu sa venujem <strong>tvorbe webových aplikácií</strong>, a to predovšetkým ich frontendovej časti.</p>
            <p>V&nbsp;súčasnosti Vám rád poskytnem svoje služby v&nbsp;rozsahu 3 MD / týždeň.</p>
          </div>
          <div class="intro__buttons">
            <div class="intro__button">Životopis</div>
            <div class="intro__button">Projekty</div>
            <div class="intro__button">Kontakt</div>
          </div>
        </div>
        <div class="intro__side">
          <div class="intro__photo"></div>
        </div>
      </div>
      <CV />
      <Projects />
      <Contact />
    </div>
  </div>
</template>

<script>
import CV from './CV.vue'
import Skills from './Skills.vue'
import Projects from './Projects.vue'
import Contact from './Contact.vue'

export default {
  name: 'Main',
  props: {},
  components: {
    CV, Skills, Projects, Contact
  }
}
</script>

<style lang="scss">
.page {
  color: $c-font-black;
}

.main {
  max-width: 100%;
  margin: 0 auto;
}

.intro {
  display: flex;
  align-items: stretch;
  // border-top: 1rem solid $c-primary;

  &__main {
    text-align: justify;
    flex-basis: 65%;
    padding: 5rem;

    strong {
      font-weight: 700;
      color: $c-secondary;
    }
  }

  &__heading {}

  &__logo {
    color: $c-primary;
    font-size: 42px;
    margin-right: .5rem;
  }

  &__name {
    font-weight: 700;
    color: $c-primary;
  }

  &__text {
    margin-top: 1em;
    margin-bottom: 1.5em;
    font-size: 26px;
    line-height: 1.5em;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    background-color: $c-yellow;
    padding: .85rem 1.25rem;
    border-radius: .35rem;
    font-weight: 600;
    font-size: 22px;
    cursor: pointer;
    border-bottom: 4px solid darken($c-yellow, 20);
    
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__side {
    flex-grow: 1;
    display: flex;
    // justify-content: center;
    // align-items: center;
    padding-bottom: 5rem;
  }

  &__photo {
    
    border-bottom-left-radius: 500px;
    border-bottom-right-radius: 500px;
    overflow: hidden;
    // box-shadow: inset 0 0 0 1rem $c-primary;
    // box-shadow: inset 0 0 2rem -.5rem $c-black;
    position: relative;
    width: 65%;

    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-color: $c-tertiary;
      // background: linear-gradient(to bottom, $c-tertiary, $c-primary);
    }
    
    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../assets/photo-removebg.png);
      // background-color: $c-tertiary;
      background-size: 135%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
</style>
